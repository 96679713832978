<template>
  <div class="reg-soc rwr-reg-soc" v-if="false">
    <a href="#"><img src="/images/soc-reg/vk.svg" style="height: 12px" alt="" /></a>
    <a href="#"><img src="/images/soc-reg/ok.svg" alt="" /></a>
    <a href="#"><img src="/images/soc-reg/tg.svg" alt="Mail.ru" /></a>
    <a href="#"><img src="/images/soc-reg/fb.svg" alt="" /></a>
    <a href="#"><img src="/images/soc-reg/gp.svg" alt="" /></a>
    <a href="#"><img src="/images/soc-reg/tw.svg" alt="" /></a>
    <a href="#"><img src="/images/soc-reg/ya.svg" alt="" /></a>
  </div>
</template>

<script>
export default {
  name: 'Socials',
  mounted() {},
}
</script>

<style scoped></style>
