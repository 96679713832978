<template>
  <div class="profile-block">
    <div class="form-select-block">
      <div class="form-select-block-title">Выберите дату:</div>
      <div class="form-select-block-select">
        <select v-if="days" @change="getData($event.target.value)">
          <option v-for="(day, index) in days" :value="index" :key="index">
            {{ format(day, 'dd MMMM', { locale: ru }) }}
          </option>
        </select>
      </div>
    </div>

    <div class="history-table-wrap">
      <div class="history-table history-table4" v-if="!this.isLoading && this.list">
        <div class="tr1">
          <div class="td1">Дата</div>
          <div class="td2">Игра</div>
          <div class="td3">Ставка</div>
          <div class="td4">Выигрыш</div>
          <div class="td5">Баланс</div>
        </div>
        <div class="overflow">
          <div class="tr2" v-for="(elem, index) in list" :key="index">
            <div class="td1">{{ format(new Date(elem?.data), 'dd.MM.yyyy') }}</div>
            <div class="td2">
              <div class="history-table-name">
                <img
                  :src="this.gameBySlug(elem?.game_slug).icon"
                  :alt="this.gameBySlug(elem?.game_slug).title"
                /><span>{{ this.gameBySlug(elem?.game_slug).title }}</span>
              </div>
            </div>
            <div class="td3">{{ n(elem?.bet) }} ₽</div>
            <div class="td4">
              <div class="tournaments-table-win-line">{{ n(elem?.win) }} ₽</div>
            </div>
            <div class="td5">{{ n(elem?.balance) }} ₽</div>
          </div>
        </div>
      </div>
      <div v-else-if="this.isLoading && !this.list">Загрузка</div>
      <div v-else>Данных нет</div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { format, subDays } from 'date-fns'
import { ru } from 'date-fns/esm/locale'
import { GET_BETS_HISTORY } from '@/store/actions.type'
import { mapGetters } from 'vuex'
import { toast } from 'vue3-toastify'

export default {
  name: 'History',
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      page: 0,
      tab: 0,
      days: null,
      list: null,
      isLoading: true,
      format,
      ru,
    }
  },
  computed: {
    ...mapGetters(['gameBySlug']),
  },
  mounted() {
    let array = []

    for (let i = 0; i <= 13; i++) {
      array.push(subDays(new Date(), i))
    }

    this.days = array

    this.getData(0)
  },
  methods: {
    getData(value) {
      this.$store
        .dispatch(GET_BETS_HISTORY, { time_zone_shift: -new Date().getTimezoneOffset() / 60, day_shift: Number(value) })
        .then(({ data }) => {
          this.list = data
          this.isLoading = false
        })
        .catch(res => {
          toast.error(res?.data?.err)
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped></style>
