<template>
  <div class="modal-wrap">
    <div class="modal-overlay" />

    <div class="modal-content" style="max-width: 100%; padding: 0 15px">
      <div class="payredirect-status-nav">
        <a class="payredirect-back" @click="this.$emit('closeModal')">Назад</a>
        <div class="back-block-carefully">
          <span class="carefully">
            <span style="color: #fff">Статус заявки: </span>
            <span style="color: #eebd11" v-if="isWaiting">подбор реквизитов</span>
            <span style="color: #fee000" v-else-if="isPending">ожидает оплаты</span>
            <span style="color: #81c105" v-else-if="isCompleted">оплачена</span>
            <span style="color: #f11932" v-else-if="isFailed">отменена</span>
          </span>
          <div class="carefully-time" v-if="isPending">
            <vue-countdown
              :time="this.payment?.paymentData?.ttl * 1000"
              v-slot="{ minutes, seconds }"
              v-if="!this.paid"
            >
              <div class="payredirect-status-timer timeTo timeTo-white" style="font-size: 18px">
                <div class="first">
                  {{ String(minutes).padStart(2, '0') }}
                </div>
                <span>:</span>
                <div>
                  {{ String(seconds).padStart(2, '0') }}
                </div>
              </div>
            </vue-countdown>
          </div>
        </div>
      </div>

      <div class="payre-block-box">
        <div class="payredirect payre-block-box1">
          <template v-if="isWaiting">
            <object
              class="preloader-logo-site"
              type="image/svg+xml"
              data="/images/mln-logo-preloader-site.svg"
            ></object>
            <div class="preloader-logo-site-time">Подбор реквизитов может занять несколько минут</div>
          </template>
          <template v-else-if="isPending">
            <div class="payredirect-status-nav2">
              <div v-if="isDuplicate">❗ У вас есть неоплаченный платеж, созданный ранее.</div>
              Отправьте <span style="color: rgb(238, 189, 17)">{{ this.payment?.paymentData?.amount }} ₽</span> на
              указанный номер карты, оплатив комиссию за перевод.
            </div>

            <div class="payredirect-status">
              <div class="payredirect-status-fl">
                <div class="payredirect-status-num">
                  <input
                    type="text"
                    readonly="readonly"
                    class="payredirect-status-num-item"
                    :value="this.payment?.paymentData?.payeeWallet"
                    @click="$event.target.select()"
                  />
                  <!--<div class="phone">8 999 888 77 77</div> для оплаты через спб-->
                </div>
                <a
                  class="payredirect-status-copy"
                  @click="this.copyToClipboard(this.payment?.paymentData?.payeeWallet, () => (this.copied = true))"
                  v-if="!this.copied"
                ></a>
                <div class="payredirect-status-copy-done" v-else><i>Скопировано</i></div>
              </div>
              <div class="payredirect-status-f2">{{ this.payment?.paymentData?.amount }}</div>
            </div>

            <div class="bank" v-if="payment?.paymentData?.payeeBankName">{{ payment.paymentData.payeeBankName }}</div>

            <div class="payre-block-box1-2" v-if="payment?.paymentData?.payeeName">
              <div class="name">{{ payment.paymentData.payeeName }}</div>
            </div>

            <div class="payre-block-submit">
              <button
                class="payredirect-submit1"
                v-if="showConfirm"
                :disabled="!this.canConfirm"
                @click="confirmPayment"
              >
                {{ !this.confirmed ? 'Я оплатил' : 'Ожидайте...' }}
              </button>
              <button class="payredirect-submit" v-if="showCancel" :disabled="!this.canCancel" @click="cancelPayment">
                Отменить платеж
              </button>
            </div>

            <a class="payredirect-close" @click="this.$emit('closeModal')"></a>
          </template>
          <template v-else-if="isCompleted">
            <div class="iCompleted">
              <div>Успешная оплата</div>
              <button class="payredirect-submit1" @click="closeCashDesk">
                {{ 'Хорошо' }}
              </button>
            </div>
          </template>
          <template v-else-if="isFailed">
            <div class="iError">
              <div>Не удалось совершить платеж</div>
              <div class="noRequisites" v-if="noRequisites">
                В данный момент нет свободных реквизитов на вашу сумму, попробуйте сумму больше.
                <span>Пополнения от 5 000 &#8381; проходят успешнее.</span>
              </div>
              <button class="payredirect-submit1" @click="this.$emit('closeModal')">
                {{ 'Вернуться' }}
              </button>
            </div>
          </template>
        </div>
        <div class="payre-block-box2">
          <div class="payre-block-box2-1">Инструкция по переводу:</div>
          <div class="payre-block-box2-2">
            Скопируйте и переведите вручную точную сумму на указанный номер карты одним платежом.
          </div>
          <div class="payre-block-box2-3">
            После перевода ожидайте зачисление средств на баланс до 5 минут. Совершать повторные переводы на карту, без
            создания новой заявки в кассе &mdash; строго запрещено.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import VueCountdown from '@chenfengyuan/vue-countdown'
import { CANCEL_DEPOSIT, CONFIRM_DEPOSIT, FETCH_PAYMENT_STATUS, LOGOUT } from '@/store/actions.type'
import { copyToClipboard } from '@/utils'
import { toast } from 'vue3-toastify'

export default {
  name: 'AwaitingPayment',
  props: ['paymentId', 'isDuplicate'],
  components: { VueCountdown },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      interval: null,
      copied: false,
      canceled: false,
      confirmed: false,
      noRequisites: false,
      payment: {},
    }
  },
  computed: {
    isCompleted() {
      return this.payment?.status === 'completed'
    },
    isWaiting() {
      return !this.payment?.status || (this.payment?.status === 'created' && !this.payment?.paymentData?.payeeWallet)
    },
    isPending() {
      return this.payment?.status === 'created' && !!this.payment?.paymentData?.payeeWallet
    },
    isFailed() {
      return this.payment?.status === 'failed'
    },
    showConfirm() {
      return this.payment?.paymentData?.canConfirm
    },
    showCancel() {
      return false // this.payment?.paymentData?.canCancel && !this.confirmed
    },
    canConfirm() {
      return !this.confirmed && !this.canceled
    },
    canCancel() {
      return false
    },
  },
  mounted() {
    if (this.paymentId) {
      this.interval = setInterval(() => {
        this.refreshStatus()
      }, 3000)
    }
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    refreshStatus() {
      this.$store.dispatch(FETCH_PAYMENT_STATUS, { paymentId: this.paymentId }).then(response => {
        switch (response?.data?.status) {
          case 'completed':
            clearInterval(this.interval)
            break
          case 'failed':
            clearInterval(this.interval)
            if (this.isWaiting) {
              this.noRequisites = true
              // this.$emit('closeModal')
              // toast.error('Не удалось совершить платеж.')
            }
            break
        }
        this.payment = response?.data
      })
    },
    copyToClipboard,
    cancelPayment() {
      this.canceled = true
      this.$store.dispatch(CANCEL_DEPOSIT, this.paymentId).then(() => {})
    },
    confirmPayment() {
      this.confirmed = true
      this.$store.dispatch(CONFIRM_DEPOSIT, this.paymentId).then(() => {})
    },
    closeCashDesk() {
      this.$emit('closeModal')
      this.$router.push({ hash: '' })
    },
  },
}
</script>

<style scoped lang="scss">
.preloader-logo-site {
  width: 50%;
}
.preloader-logo-site-time {
  text-align: center;
}
.iCompleted {
  text-align: center;
  align-content: center;
  height: 100%;
  font-size: 35px;
  color: rgb(129, 193, 5);
}
.iError {
  text-align: center;
  align-content: center;
  height: 100%;
  font-size: 25px;
  color: #f11932;
}
.iError .noRequisites {
  margin-top: 15px;
  font-size: 15px;
  color: #c0c0c0;
}
.iError .noRequisites span {
  color: #c0c0c0;
  display: block;
  margin-top: 10px;
}
.iCompleted button,
.iError button {
  margin-top: 20px;
  border: 0;
}
input.payredirect-status-num-item {
  border: 0;
  color: white;
}
</style>
