<template>
  <div class="slider-block">
    <swiper
      :slides-per-view="1"
      :modules="modules"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }"
      :navigation="{ nextEl: '.sld1-next', prevEl: '.sld1-prev' }"
      :pagination="{ clickable: true }"
    >
      <!-- <swiper-slide>
        <div class="slider-item slider12-0" style="background-image: url(/images/slider/12.webp)">
          <div class="slider-item-w">
            <div class="slide-form">
              <div class="slider-banner slider11 slider12">
                <div class="slider-banner-img1">
                  <img src="/images/slider12.svg" />
                  <img src="/images/slider12-1.webp" />
                </div>

                <div class="slider-banner-title2">Новогодние подарки<br />ждут тебя</div>
                <a href="/tournaments/novogodniimln2025" class="slider-banner-btn">Подробнее</a>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>-->
      <swiper-slide>
        <div class="slider-item slide-mob-position1" style="background-image: url(/images/slider/1.webp)">
          <div class="slider-item-w">
            <div class="slide-form">
              <div class="reg-block">
                <div class="reg-block-l">
                  <div class="reg-block-bonus">
                    <div class="reg-block-bonus-title">Приветственный пакет бонусов</div>
                    <div class="reg-block-bonus-prc">250%</div>
                    <div class="reg-block-bonus-plus">+ 30 фриспинов</div>
                    <router-link
                      :to="{ name: 'register' }"
                      class="mob-reg-slider-btn registration-btn only-open-reg"
                      v-if="!isAuthenticated"
                      >Регистрация</router-link
                    >
                    <router-link :to="{ name: 'bonuses' }" class="reg-block-more">Подробнее</router-link>
                  </div>
                </div>
                <div class="reg-block-r" v-if="!isAuthenticated">
                  <div class="tt-tabs tabs-reg">
                    <div class="index-tabs">
                      <span @click="this.regmethod = 'phone'" :class="this.regmethod === 'phone' ? 'active-ttab' : ''"
                        >· Телефон ·</span
                      >
                      <span @click="this.regmethod = 'email'" :class="this.regmethod === 'email' ? 'active-ttab' : ''"
                        >· Почта ·</span
                      >
                    </div>
                    <div class="index-panel">
                      <div v-if="this.regmethod === 'phone'" class="tt-panel">
                        <form @submit.prevent.stop="this.onSubmit">
                          <div class="form-line">
                            <phone :setValue="data => (this.phone = data)" :value="this.phone" />
                          </div>
                          <div class="form-line">
                            <div class="form-line-promo">
                              <div class="form-line-promo-l">
                                <input
                                  type="text"
                                  class="input-base"
                                  name=""
                                  placeholder="Промокод, если есть"
                                  v-model="promocode"
                                />
                              </div>

                              <currency :setValue="data => (this.currency = data)" :value="this.currency" />
                            </div>
                          </div>
                          <button type="submit" class="registration-btn" :disabled="!phone">
                            {{ this.isLoading ? 'Подождите' : 'Регистрация' }}
                          </button>
                        </form>
                      </div>
                      <div v-if="this.regmethod === 'email'" class="tt-panel done">
                        <form @submit.prevent.stop="this.onSubmit">
                          <div class="form-line">
                            <input type="text" class="input-base" name="" placeholder="Введите почту" v-model="email" />
                            <span class="error" v-if="emailErrorMessage">{{ emailErrorMessage }}</span>
                          </div>
                          <div class="form-line">
                            <input
                              type="text"
                              name=""
                              class="rwr-input"
                              placeholder="Логин"
                              v-model="username"
                              required
                            />
                          </div>
                          <div class="form-line">
                            <input
                              :type="passwordFieldType"
                              name=""
                              class="rwr-input"
                              placeholder="Пароль"
                              v-model="password"
                              required
                            />
                            <i class="icp" @click="switchVisibility"></i>
                            <span class="error" v-if="passwordErrorMessage">{{ passwordErrorMessage }}</span>
                          </div>

                          <!--                          <div class="form-line">-->
                          <!--                            <div class="form-line-promo">-->
                          <!--                              <div class="form-line-promo-l">-->
                          <!--                                <input-->
                          <!--                                  type="text"-->
                          <!--                                  class="input-base"-->
                          <!--                                  name=""-->
                          <!--                                  placeholder="Промокод, если есть"-->
                          <!--                                  v-model="promocode"-->
                          <!--                                />-->
                          <!--                              </div>-->

                          <!--                              <currency :setValue="data => (this.currency = data)" :value="this.currency" />-->
                          <!--                            </div>-->
                          <!--                          </div>-->
                          <button
                            type="submit"
                            class="registration-btn"
                            :disabled="!email || !password || !username || passwordErrorMessage || emailErrorMessage"
                          >
                            {{ this.isLoading ? 'Подождите' : 'Регистрация' }}
                          </button>
                        </form>
                      </div>

                      <socials />

                      <div class="reg-label">
                        <span>Уже зарегистрированы?</span>
                        <router-link :to="{ name: 'login' }" class="only-open-login">Войти</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="slider-item" style="background-image: url(/images/slider/10.webp)">
          <div class="slider-item-w">
            <div class="slide-form">
              <div class="slider-banner">
                <div class="slider-banner-label">Лучшая</div>
                <div class="slider-banner-title">Многоуровневая система бонусов</div>
                <div class="slider-banner-title2">Бонусы при<br />достижении уровня</div>
                <div class="slider-banner-images">
                  <span><img src="/images/slider-banner1.svg" alt="" />MacBook</span>
                  <span><img src="/images/slider-banner2.svg" alt="" />iPhone</span>
                </div>
                <router-link
                  :to="{ name: 'register' }"
                  class="mob-reg-slider-btn registration-btn only-open-reg"
                  v-if="!isAuthenticated"
                  >Регистрация</router-link
                >
                <router-link :to="{ name: 'levels' }" class="slider-banner-btn">Подробнее</router-link>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="slider-item slider11-0" style="background-image: url(/images/slider/11.webp)">
          <div class="slider-item-w">
            <div class="slide-form">
              <div class="slider-banner-li-2">
                <ul>
                  <li>Новости</li>
                  <li>Фриспины</li>
                  <li>Бонусы</li>
                  <li>Зеркало</li>
                </ul>
              </div>
              <div class="slider-banner slider11">
                <div class="slider-banner-img1">
                  <img src="/images/slider11.svg" />
                  <img src="/images/slider11-1.svg" />
                </div>
                <div class="slider-banner-li">
                  <ul>
                    <li>Новости</li>
                    <li>Бонусы</li>
                    <li>Фриспины</li>
                    <li>Зеркало</li>
                  </ul>
                </div>
                <div class="slider-banner-title2">Подпишись на<br />телеграм канал</div>
                <router-link
                  :to="{ name: 'register' }"
                  class="mob-reg-slider-btn registration-btn only-open-reg"
                  v-if="!isAuthenticated"
                  >Регистрация</router-link
                >
                <a href="https://t.me/millionb_club" class="slider-banner-btn">Подписаться</a>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <div class="sld1-prev slider-arrow"></div>
    <div class="sld1-next slider-arrow slider-arrow-right"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { REGISTER } from '@/store/actions.type'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import Socials from '@/components/Socials'
import Currency from '@/components/Fields/Currency'
import Phone from '@/components/Fields/Phone'
import { useI18n } from 'vue-i18n'
import { toast } from 'vue3-toastify'
import { emailValidation, passwordValidation } from '@/utils'
import { getSavedRefId } from '@/common/referal'

export default {
  name: 'Slider',
  data() {
    return {
      promocode: null,
      phone: null,
      email: null,
      username: null,
      password: null,
      currency: 'RUB',
      regmethod: 'phone',
      isLoading: false,
      passwordFieldType: 'password',
      passwordErrorMessage: null,
      emailErrorMessage: null,
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    Socials,
    Currency,
    Phone,
  },
  setup() {
    const { t, locale } = useI18n()

    return {
      t,
      locale,
      modules: [Autoplay, Pagination, Navigation],
    }
  },
  watch: {
    password: function () {
      this.passwordErrorMessage = passwordValidation(this.username, this.password)
    },
    username: function () {
      if (this.password) {
        this.passwordErrorMessage = passwordValidation(this.username, this.password)
      }
    },
    email: function () {
      if (this.email) {
        this.emailErrorMessage = emailValidation(this.email)
      }
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    onSubmit() {
      if (!this.isLoading) {
        this.isLoading = true
        this.$store
          .dispatch(REGISTER, {
            registration_type: this.regmethod,
            username: this.username,
            email: this.email,
            password: this.password,
            currency: this.currency,
            lang: this.locale,
            phone: this.phone,
            ref_id: getSavedRefId(),
          })
          .then(res => {
            this.isLoading = false
            this.$router.push({ name: 'home', hash: '#reg-success' + (this.regmethod === 'phone' ? '-phone' : '') })
          })
          .catch(res => {
            this.isLoading = false
            toast.error(res?.data?.err)
          })
      }
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style scoped></style>
