<template>
  <div class="pay-select-summ-hid">
    <v-form class="pay-select-summ" @submit="onSubmit" ref="form" validate-on-mount>
      <div class="pay-select-summ-caption">Сумма:</div>
      <div class="pay-select-summ-items">
        <label
          class="pay-select-summ-item"
          v-for="elem in this.provider?.suggestedValues"
          :key="elem"
          @click="this.choseValue(elem)"
        >
          <input type="radio" :checked="amount === elem" />
          <i />
          <span>{{ elem }}</span>
        </label>
      </div>

      <div class="field-block">
        <v-field
          name="amount"
          v-model="amount"
          type="text"
          class="suminput"
          :rules="minMax"
          mode="aggressive"
          placeholder="0"
          maxlength="7"
          v-on:keypress="numbersOnly"
        />
        <div class="rub-static"><span>&#8381;</span></div>
        <div class="pm-block">
          <div class="pm-block-btn">
            <div class="plus" @click="inc(500)">+</div>
            <div class="minus" @click="inc(-500)">-</div>
          </div>
        </div>
        <error-message name="amount" />
      </div>

      <button class="pay-order-btn" type="submit" :disabled="this.isLoading">
        {{ !isLoading ? 'Пополнить' : 'Ожидайте...' }}
      </button>
    </v-form>
  </div>

  <awaiting-payment
    v-if="this.show || devWait"
    v-on:closeModal="this.show = false"
    :paymentId="this.paymentData?.paymentId"
    :isDuplicate="this.paymentData?.isDuplicate"
  />
</template>

<script>
import { useI18n } from 'vue-i18n'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { SEND_PAYMENT } from '@/store/actions.type'
import { minMaxValidation } from '@/utils'
import AwaitingPayment from './AwaitingPayment'
import { toast } from 'vue3-toastify'

export default {
  name: 'DefaultController',
  props: {
    provider: {
      type: Object,
      required: true,
    },
  },
  components: {
    VForm: Form,
    VField: Field,
    ErrorMessage,
    AwaitingPayment,
  },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      amount: null,
      isLoading: false,
      show: false,
      paymentData: null,
      devWait: false, // <-- switch this
    }
  },
  watch: {
    provider: function () {
      this.amount = null
      this.$refs.form.resetField('amount', null)
    },
  },
  methods: {
    choseValue(value) {
      this.amount = value
      this.$refs.form.setFieldValue('amount', value, true)
    },
    minMax(value) {
      return minMaxValidation(value, this.provider)
    },
    inc(amount) {
      this.amount = parseFloat(this.amount || '0') + amount
      if (this.amount < this.provider?.min) {
        this.amount = this.provider?.min
      } else if (this.amount > this.provider?.max) {
        this.amount = this.provider?.max
      }
    },
    returnStatusByController() {
      return this.provider.provider
      // if (this.provider.controller === 'redirect') {
      //   return 'action_required_redirect'
      // } else if (this.provider.controller === 'p2p') {
      //   return 'created_completed'
      // } else {
      //   return null
      // }
    },
    onSubmit(values, { resetForm }) {
      this.isLoading = true
      const amount = parseFloat(this.amount)
      if (!amount) {
        return
      }

      this.$store
        .dispatch(SEND_PAYMENT, {
          provider: this.returnStatusByController(), // action_required_redirect, created_completed, created_failed, conflict
          amount: amount,
          currency: 'RUR',
          locationId: window.location.hostname,
        })
        .then(({ data }) => {
          if (data?.status === 'action_required') {
            if (data?.action?.type === 'redirect') {
              window.location.href = data?.action?.value
            } else if (data?.action?.type === 'script') {
              const tag = document.createElement('script')
              let range = document.createRange()
              let documentFragment = range.createContextualFragment(data.action.value)
              document.head.appendChild(documentFragment)
            }
          } else if (data?.status === 'created') {
            this.paymentData = data
            this.show = true
            resetForm()
          } else if (data?.status === 'conflict') {
            this.paymentData = data
            this.show = true
            resetForm()
          } else if (data?.status === 'failed') {
            toast.error(data?.message)
          }

          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          toast.error('Ошибка пополнения счёта. Попробуйте еще раз, либо выберите другой способ пополнения.')
        })
    },

    //Только цифры в input
    numbersOnly(evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
