import {
  FETCH_CASHDESK_CONFIG,
  FETCH_PROFILE,
  SEND_PAYMENT,
  FETCH_PAYMENT_STATUS,
  CHANGE_PASSWORD,
  CHANGE_PROFILE,
  GET_PAYMENTS_HISTORY,
  GET_BETS_HISTORY,
  FETCH_FAVOURITES,
  REMOVE_FROM_FAVOURITE,
  ADD_TO_FAVOURITE,
  FETCH_MESSAGES,
  READ_MESSAGE,
  WITHDRAW_PAYMENT,
  FETCH_PENDING_PAYOUTS,
  REVERT_PAYMENT,
  CANCEL_DEPOSIT,
  CONFIRM_DEPOSIT,
} from './actions.type'
import {
  SET_CASHDESK_CONFIG,
  SET_PROFILE,
  SET_FAVOURITES,
  SET_PAYMENT_RESULT,
  SET_MESSAGES,
  MARK_MESSAGE_READ,
  SET_PENDING_PAYOUTS,
} from './mutations.type'

import ApiService, { ProfileService } from '@/common/api.service'
import { API_REALM } from '@/common/config'

const state = {
  favourites: [],
  messages: {},
  errors: {},
  profile: {},
  config: {},
}

const getters = {
  favourites(state) {
    return state.favourites
  },
  profile(state) {
    return state.profile
  },
  deposit(state) {
    const layout = state.config?.deposit?.layouts[0] || []
    const systems = state.config?.deposit?.systems
    const cGroups = state.config?.deposit?.groups
    const groups: any = []
    layout?.items?.map(elem => {
      switch (elem.type) {
        case 'group':
          elem.slug.map(gElem => {
            for (const i in cGroups) {
              if (gElem === cGroups[i].slug) {
                // @ts-ignore
                groups.push(cGroups[i])
              }
            }
          })
          break
        case 'system':
          elem.slug.map(sElem => {
            for (const i in systems) {
              if (sElem === systems[i].provider) {
                // @ts-ignore
                groups.push(systems[i])
              }
            }
          })
          break
      }
    })
    return groups.map(elem => {
      if (elem?.systems) {
        const subGroups = []
        elem?.systems?.map(elem => {
          for (const i in systems) {
            if (elem === systems[i].provider) {
              // @ts-ignore
              subGroups.push(systems[i])
            }
          }
        })
        return { ...elem, systems: subGroups }
      }
      return elem
    })
  },
  withdraw(state) {
    const layout = state.config?.withdraw?.layouts[0] || []
    const systems = state.config?.withdraw?.systems
    const cGroups = state.config?.withdraw?.groups
    const groups: any = []
    layout?.items?.map(elem => {
      switch (elem.type) {
        case 'group':
          elem.slug.map(gElem => {
            for (const i in cGroups) {
              if (gElem === cGroups[i].slug) {
                // @ts-ignore
                groups.push(cGroups[i])
              }
            }
          })
          break
        case 'system':
          elem.slug.map(sElem => {
            for (const i in systems) {
              if (sElem === systems[i].provider) {
                // @ts-ignore
                groups.push(systems[i])
              }
            }
          })
          break
      }
    })
    return groups.map(elem => {
      if (elem?.systems) {
        const subGroups = []
        elem?.systems?.map(elem => {
          for (const i in systems) {
            if (elem === systems[i].provider) {
              // @ts-ignore
              subGroups.push(systems[i])
            }
          }
        })
        return { ...elem, systems: subGroups }
      }
      return elem
    })
  },
}

const actions = {
  [FETCH_PROFILE](context, payload) {
    const { username } = payload
    return ApiService.get('profiles', username)
      .then(({ data }) => {
        context.commit(SET_PROFILE, data.profile)
        return data
      })
      .catch(({ response }) => {
        // context.commit(SET_ERROR, response?.data?.error)
        console.log(response)
      })
  },
  [FETCH_CASHDESK_CONFIG](context) {
    return ProfileService.get('payments/config')
      .then(({ data }) => {
        context.commit(SET_CASHDESK_CONFIG, data.data)
        return data
      })
      .catch(({ response }) => {
        // context.commit(SET_ERROR, response?.data?.error)
        console.log(response)
      })
  },
  [SEND_PAYMENT](context, payload) {
    return ApiService.post('user/payments/deposit', payload)
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        // context.commit(SET_ERROR, response?.data?.error)
        console.log(response)
      })
  },
  [WITHDRAW_PAYMENT](context, payload) {
    return ApiService.post('user/payments/withdraw', payload)
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        // context.commit(SET_ERROR, response?.data?.error)
        console.log(response)
      })
  },
  [REVERT_PAYMENT](context, hash) {
    return ApiService.post('user/payments/revert', { paymentId: hash })
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        // context.commit(SET_ERROR, response?.data?.error)
        console.log(response)
      })
  },
  [CANCEL_DEPOSIT](context, paymentId) {
    return ApiService.post('user/payments/deposit/cancel', { paymentId: paymentId })
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        // context.commit(SET_ERROR, response?.data?.error)
        console.log(response)
      })
  },
  [CONFIRM_DEPOSIT](context, paymentId) {
    return ApiService.post('user/payments/deposit/confirm', { paymentId: paymentId })
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        // context.commit(SET_ERROR, response?.data?.error)
        console.log(response)
      })
  },
  [FETCH_PENDING_PAYOUTS](context, payload) {
    return ApiService.post('user/payments/pending', payload)
      .then(({ data }) => {
        context.commit(SET_PENDING_PAYOUTS, data.data)
      })
      .catch(({ response }) => {
        // context.commit(SET_ERROR, response?.data?.error)
        console.log(response)
      })
  },
  [FETCH_PAYMENT_STATUS](context, payload) {
    return ApiService.post('user/payments/status', payload)
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        // context.commit(SET_ERROR, response?.data?.error)
        console.log(response)
      })
  },
  [CHANGE_PROFILE](context, payload) {
    return new Promise((resolve, reject) => {
      return ApiService.put('user', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
  [CHANGE_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      return ApiService.post('user/password', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
  [GET_PAYMENTS_HISTORY](context, payload) {
    return new Promise((resolve, reject) => {
      return ApiService.post('user/payments', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
  [GET_BETS_HISTORY](context, payload) {
    return new Promise((resolve, reject) => {
      return ApiService.post('user/bets', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
  [FETCH_FAVOURITES](context) {
    return new Promise((resolve, reject) => {
      return ApiService.get('user/favourites')
        .then(({ data }) => {
          context.commit(SET_FAVOURITES, data.data)
          resolve(data.data)
        })
        .catch(response => {
          reject(response)
        })
    })
  },
  [REMOVE_FROM_FAVOURITE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('user/favourites/update', { remove_game_slugs: [payload.slug] })
        .then(({ data }) => {
          context.commit(SET_FAVOURITES, data.data)
          resolve(data.data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
  [ADD_TO_FAVOURITE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('user/favourites/update', { add_game_slugs: [payload.slug] })
        .then(({ data }) => {
          context.commit(SET_FAVOURITES, data.data)
          resolve(data.data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
  [FETCH_MESSAGES](context) {
    return new Promise((resolve, reject) => {
      return ApiService.get('user/messages')
        .then(({ data }) => {
          context.commit(SET_MESSAGES, data.data)
          resolve(data.data)
        })
        .catch(response => {
          reject(response)
        })
    })
  },
  [READ_MESSAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('user/messages/read', { messageId: payload.messageId.toString() })
        .then(({ data }) => {
          context.commit(MARK_MESSAGE_READ, payload.messageId)
          resolve(data)
        })
        .catch(response => {
          reject(response)
        })
    })
  },
}

const mutations = {
  [SET_FAVOURITES](state, favourites) {
    state.favourites = favourites.game_slugs
    state.errors = {}
  },
  [SET_PROFILE](state, profile) {
    state.profile = profile
    state.errors = {}
  },
  [SET_CASHDESK_CONFIG](state, config) {
    state.config = config
    state.errors = {}
  },
  [SET_MESSAGES](state, messages) {
    state.messages = messages
    state.errors = {}
  },
  [MARK_MESSAGE_READ](state, id) {
    if (!state.messages || !state.messages.messages) {
      return
    }
    const foundMsg = state.messages.messages.find((msg: { id: any }) => msg.id === id)
    foundMsg.recipient.is_read = true
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
