<template>
  <div class="pay-select-summ-hid">
    <v-form class="pay-select-summ pay-select-summ-column btn-paycosout1" @submit="onSubmit" validate-on-mount>
      <div class="pay-select-top">
        <div class="enter-card">
          <div class="enter-card-title">
            {{ t(provider.controllerParams?.accountTitle || 'Номер счета') }}
            <i v-if="provider.controllerParams?.note">{{ provider.controllerParams.note }}</i>
          </div>

          <div class="field-block">
            <v-field
              name="wallet"
              type="text"
              class="enter-card-input"
              v-model="wallet"
              :rules="requiredValidation"
              mode="aggressive"
              :placeholder="provider.controllerParams?.walletPlaceholder ?? '0000 0000 0000 0000'"
            />
            <error-message name="wallet" />
          </div>
          <div class="save-card">
            <button
              type="button"
              :class="`enter-card-favorite ${isSaveWallet ? 'active' : ''}`"
              @click="isSaveWallet = !isSaveWallet"
            />
            <div class="save-card-btn">Сохранить карту</div>
          </div>
        </div>

        <div class="enter-card">
          <div class="pay-select-summ-caption">Сумма:</div>

          <div class="field-block">
            <v-field
              name="amount"
              type="number"
              class="suminput"
              v-model="amount"
              :rules="minMax"
              mode="aggressive"
              placeholder="0"
              maxlength="7"
              v-on:keypress="numbersOnly"
            />
            <div class="rub-static"><span>&#8381;</span></div>
            <div class="pm-block">
              <div class="max-block" @click="setMaxAmount">макс</div>
              <div class="pm-block-btn">
                <div class="plus" @click="inc(500)">+</div>
                <div class="minus" @click="inc(-500)">-</div>
              </div>
            </div>
            <error-message name="amount" />
          </div>
        </div>

        <button class="pay-order-btn" type="submit" :disabled="this.isLoading">
          {{ !isLoading ? 'Получить' : 'Ожидайте...' }}
        </button>
      </div>

      <div class="pay-select-bot">
        <div class="enter-card namecart">
          <div class="pay-select-summ-caption">Имя на карте:</div>
          <div class="field-block">
            <v-field
              name="name"
              type="text"
              class="enter-card-input"
              v-model="name"
              :rules="requiredValidation"
              mode="aggressive"
              placeholder="Введите имя"
            />
            <error-message name="name" />
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { ErrorMessage, Field, Form } from 'vee-validate'
import AwaitingPayment from '../TopUp/AwaitingPayment'
import { minMaxValidation, requiredValidation } from '@/utils'
import { FETCH_PAYSYSTEM_CONFIG, FETCH_PENDING_PAYOUTS, WITHDRAW_PAYMENT } from '@/store/actions.type'
import { toast } from 'vue3-toastify'
import { mapGetters } from 'vuex'

export default {
  name: 'CardWithNameController',
  emits: ['success', 'error'],
  props: {
    provider: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      wallet: null,
      isSaveWallet: false,
      amount: null,
      name: null,
      isLoading: false,
    }
  },
  components: {
    VForm: Form,
    VField: Field,
    ErrorMessage,
    AwaitingPayment,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  mounted() {
    this.$store.dispatch(FETCH_PAYSYSTEM_CONFIG, this.provider.slug).then(cfg => {
      if (cfg.wallet) {
        this.wallet = cfg.wallet
      }
      this.isSaveWallet = cfg.isSaveWallet
    })
  },
  methods: {
    requiredValidation,
    minMax(value) {
      return minMaxValidation(value, this.provider)
    },
    inc(amount) {
      this.amount = parseFloat(this.amount || '0') + amount
      if (this.amount < this.provider?.min) {
        this.amount = this.provider?.min
      } else if (this.amount > this.provider?.max) {
        this.amount = this.provider?.max
      }

      if (this.amount > this.currentUser?.status?.balance) {
        this.amount = Math.floor(this.currentUser?.status?.balance)
      }
    },
    setMaxAmount() {
      this.amount = Math.floor(this.currentUser?.status?.balance)
      this.inc(0)
    },
    onSubmit() {
      this.isLoading = true
      this.$store
        .dispatch(WITHDRAW_PAYMENT, {
          provider: this.provider.slug,
          amount: this.amount,
          currency: 'RUB',
          locationId: window.location.href,
          wallet: this.wallet,
          name: this.name,
          isSaveWallet: this.isSaveWallet,
          extra: {
            name: this.name,
          },
        })
        .then(({ data }) => {
          this.show = true

          if (!data?.success) {
            toast.error(data?.msg)
            this.$emit('error')
          } else {
            toast.info(data?.msg)
            this.$emit('success')
          }
          this.$store.dispatch(FETCH_PENDING_PAYOUTS)

          this.isLoading = false
        })
    },
    //Только цифры в input
    numbersOnly(evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped></style>
