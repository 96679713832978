<template>
  <div class="phone-verification-promo-code" v-if="phoneVerification">
    <div class="title">Для получения бонуса вам необходимо подтвердить номер телефона.</div>
    <div>
      <VerificationPhone @confirmed="phoneConfirmed" />
    </div>
  </div>

  <div class="profile-promo-wrap" v-else>
    <div class="profile-promo-title">Активируйте Ваш персональный промокод:</div>
    <input class="profile-promo" type="text" name="" placeholder="Введите бонус-код" v-model="promoCode" />
    <a href="#" class="orange-btn profile-promo-btn" @click.prevent="activate">{{
      this.loading ? 'Подождите' : 'Получить'
    }}</a>
    <BonusActivationResult :activation-message="this.activationMessage" />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import { ACTIVATE_BONUS } from '@/store/actions.type'
import { toast } from 'vue3-toastify'
import BonusActivationResult from '@/components/cashdesk/components/BonusActivationResult.vue'
import VerificationPhone from '@/components/cashdesk/Verification/Phone.vue'

export default {
  name: 'CashdeskPromoCode',
  components: { VerificationPhone, BonusActivationResult },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      promoCode: '',
      activationMessage: null,
      loading: false,
      phoneVerification: false,
      code: null,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    activate() {
      if (!this.promoCode || this.loading) {
        return
      }
      this.code = null
      this.activationMessage = null
      this.loading = true
      this.$store
        .dispatch(ACTIVATE_BONUS, {
          promoCode: this.promoCode,
        })
        .then(data => {
          if (data.actionRequired === 'userConfirmationRequired') {
            this.phoneVerification = true
            this.code = name
          } else {
            this.activationMessage = data
          }
        })
        .catch(res => {
          toast.error(res?.data?.err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    phoneConfirmed() {
      this.phoneVerification = false
      this.activate(this.code)
    },
  },
}
</script>

<style scoped>
.phone-verification-promo-code .title {
  margin-bottom: 40px;
}
.phone-verification-promo-code {
  width: 300px;
  margin: 0 auto;
}
</style>
