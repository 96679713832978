import { getToken, parsedToken, saveToken } from '@/common/jwt.service'
import store from '@/store'
import { CHECK_AUTH, LOGOUT } from '@/store/actions.type'
import { NO_MIGRATE } from '@/common/config'

const tokenPfx = '#t#'
export function migrateUser() {
  if (window.location.hash.startsWith(tokenPfx)) {
    const token = window.location.hash.slice(tokenPfx.length)
    saveToken(token)
    store
      .dispatch(CHECK_AUTH)
      .then(() => {
        window.location.hash = '#migrated'
      })
      .catch(() => {
        store.dispatch(LOGOUT)
        window.location.hash = ''
      })

    return
  }

  doMigrateOnToken()
}

export function doMigrateOnToken() {
  const tokenClaims = parsedToken()
  if (!NO_MIGRATE && tokenClaims?.domain && tokenClaims?.domain !== window.location.host) {
    window.location.assign('https://' + tokenClaims?.domain + window.location.search + tokenPfx + getToken())
  }
}
