const refIdKey = 'refId'
export function getSavedRefId(): string | null {
  return localStorage.getItem(refIdKey)
}

export function saveRefId(refId: string): void {
  if (!!refId) {
    localStorage.setItem(refIdKey, refId)
  }
}

export function updateRefIdFromQuery() {
  const urlParams = new URLSearchParams(window.location.search)
  const refId = urlParams.get('ref')
  if (!!refId) {
    saveRefId(refId)
  }
}
