<template>
  <div :class="`pay-wrap ${activeGroupName}`" v-if="deposit">
    <template v-if="!isGroup">
      <template v-for="(group, index) in deposit" :key="group.slug">
        <a
          @click="this.choseProvider(group, index)"
          :class="`pay-item ${provider?.slug === group.slug ? 'pay-item-active' : ''}
          pay-in btn-${group?.slug}`"
        >
          <img :src="group?.icons?.default" :alt="group.slug" />
          <span v-if="group?.icons?.label">{{ group.icons.label }}</span>
        </a>

        <template v-if="this.showBlockForPayment(deposit, this.providerIndex, index)">
          <default-controller v-if="provider && !provider?.systems" :provider="provider" :key="provider?.slug" />
        </template>
      </template>
    </template>

    <template v-else>
      <div class="back-block">
        <a @click="this.goToBackStep" class="back-btn"
          ><div class="footer-slider-prev slider-arrow" />
          Назад</a
        >
        <span class="carefully"
          >Трудности с оплатой &mdash; увеличьте сумму. Пополнения от 5 000 &#8381; проходят успешнее.</span
        >
        <span class="carefully1">При оплате криптовалютой получите +10% к балансу.</span>
      </div>

      <template v-for="(system, index) in provider?.systems" :key="system.slug">
        <a
          @click="this.choseSubProvider(system, index)"
          :class="`pay-item ${subProvider?.slug === system.slug ? 'pay-item-active' : ''}
          pay-in btn-${system?.slug}`"
        >
          <img :src="system?.icons?.default" :alt="system.slug" />
          <span v-if="system?.icons?.label">{{ system.icons.label }}</span>
        </a>

        <template v-if="this.showBlockForPayment(provider?.systems, this.subProviderIndex, index)">
          <default-controller v-if="provider && subProvider" :provider="subProvider" :key="subProvider?.slug" />
        </template>
      </template>
    </template>
  </div>

  <success-payment v-if="this.showSuccess" v-on:closeModal="this.showSuccess = false" />
</template>

<script>
import { useI18n } from 'vue-i18n'
import VueCountdown from '@chenfengyuan/vue-countdown'
import { mapGetters } from 'vuex'
import DefaultController from './DefaultController.vue'
import { showBlockForPayment } from '@/utils'
import SuccessPayment from './SuccessPayment'

export default {
  name: 'CashdeskTopUp',
  components: { DefaultController, VueCountdown, SuccessPayment },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      showSuccess: false,
      secondStep: false,
      provider: null,
      providerIndex: null,
      subProvider: null,
      subProviderIndex: null,
    }
  },
  computed: {
    ...mapGetters(['deposit']),
    isGroup() {
      return this.provider?.systems
    },
    activeGroupName() {
      return !this.provider?.systems ? '' : 'group group-' + this.provider.slug
    },
  },
  mounted() {
    if (this.$route?.hash === '#payment-success') {
      this.showSuccess = true
    }
  },
  methods: {
    showBlockForPayment,
    goToBackStep() {
      this.provider = null
      this.providerIndex = null
      this.subProviderIndex = null
      this.subProvider = null
      this.secondStep = null
    },
    choseSubProvider(group, index) {
      this.subProvider = group
      this.subProviderIndex = index
    },
    choseProvider(group, index) {
      if (this.provider?.slug === group?.slug) {
        this.provider = null
        this.providerIndex = null
      } else {
        this.provider = group
        this.providerIndex = index
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
