<template>
  <div class="head-line head-line-g">
    <div class="head-line-w">
      <router-link :to="{ name: 'home' }" class="game-close" />
      <div class="game-head-title"><span style="color: #a6d007" v-if="game.title">Слот</span> {{ game.title }}</div>

      <div class="logo">
        <router-link :to="{ name: 'home' }" />
        <img class="logobg" src="/images/Frame7.svg" alt="" />
        <img class="logobg-mob" src="/images/Frame8.svg" alt="" />
        <img class="greenl" src="/images/green.svg" alt="" />
        <object class="logoobj" type="image/svg+xml" data="/images/mln-logo-normal.svg" />
      </div>

      <top-user v-if="isAuthenticated" />
      <div class="head-line-r" v-else>
        <router-link :to="{ name: 'register' }" class="orange-btn-small head-login-button"
          >Играть на деньги</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import TopUser from '@/components/TopUser'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import { LOGOUT } from '@/store/actions.type'
import UserLevelImg from '@/components/UserLevelImg.vue'
import { Talkme } from '@/components/talkme'

export default {
  name: 'TheHeaderGame',
  components: {
    UserLevelImg,
    TopUser,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'game']),
  },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      openDropdown: false,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: 'home' })
      })
    },
    support() {
      Talkme.open()
    },
  },
}
</script>

<style scoped>
.head-line {
  position: relative;
  background: #002a01;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}
.head-line-w {
  padding: 0 65px;
  margin: 0 auto;
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}
</style>
