<template>
  <div class="pay-wrap" v-if="withdraw">
    <template v-if="!provider?.systems">
      <template v-for="(group, index) in withdraw" :key="group.slug">
        <a
          @click="this.choseProvider(group, index)"
          :class="`pay-item ${provider?.slug === group.slug ? 'pay-item-active' : ''}
          pay-out btn-${group?.slug}`"
        >
          <img :src="group?.icons?.default" :alt="group.slug" />
          <span v-if="group?.icons?.label">{{ group.icons.label }}</span>
        </a>

        <template v-if="this.showBlockForPayment(withdraw, this.providerIndex, index)">
          <sbp-controller
            v-if="provider && !provider?.systems && provider?.controller === 'sbp'"
            :provider="provider"
            :key="`sbp-${provider.slug}`"
          />
          <card-with-name-controller
            v-else-if="provider && !provider?.systems && provider?.controller === 'card-with-name'"
            :provider="provider"
            :key="`card-with-name-${provider.slug}`"
          />
          <sbp-with-name-controller
            v-else-if="provider && !provider?.systems && provider?.controller === 'sbp-with-name'"
            :provider="provider"
            :key="`sbp-with-name-${provider.slug}`"
          />
          <default-controller v-else-if="provider && !provider?.systems" :provider="provider" :key="provider.slug" />
        </template>
      </template>
    </template>

    <template v-else>
      <div class="back-block">
        <a @click="this.goToBackStep" class="back-btn"
          ><div class="footer-slider-prev slider-arrow" />
          Назад</a
        >
      </div>

      <template v-for="(system, index) in provider?.systems" :key="system.slug">
        <a
          @click="this.choseSubProvider(system, index)"
          :class="`pay-item ${subProvider?.slug === system.slug ? 'pay-item-active' : ''}
          pay-out btn-${system?.slug}`"
        >
          <img :src="system?.icons?.default" :alt="system.slug" />
          <span v-if="system?.icons?.label">{{ system.icons.label }}</span>
        </a>

        <template v-if="this.showBlockForPayment(provider?.systems, this.subProviderIndex, index)">
          <default-controller
            v-if="provider && subProvider && subProvider?.controller === 'default'"
            :provider="subProvider"
            :key="subProvider.slug"
          />
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import DefaultController from './DefaultController.vue'
import SbpController from './SbpController.vue'
import { showBlockForPayment } from '@/utils'
import CardWithNameController from '@/components/cashdesk/GetWin/CardWithName.vue'
import SbpWithNameController from '@/components/cashdesk/GetWin/SbpWithName.vue'

export default {
  name: 'CashdeskGetWin',
  props: {},
  components: { SbpWithNameController, CardWithNameController, DefaultController, SbpController },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      secondStep: false,
      provider: null,
      providerIndex: null,
      subProvider: null,
      subProviderIndex: null,
    }
  },
  computed: {
    ...mapGetters(['withdraw']),
  },
  methods: {
    showBlockForPayment,
    goToBackStep() {
      this.provider = null
      this.providerIndex = null
      this.subProviderIndex = null
      this.subProvider = null
      this.secondStep = null
    },
    choseSubProvider(group, index) {
      this.subProvider = group
      this.subProviderIndex = index
    },
    choseProvider(group, index) {
      if (this.provider?.slug === group?.slug) {
        this.provider = null
        this.providerIndex = null
      } else {
        this.provider = group
        this.providerIndex = index
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
