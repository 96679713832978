<template>
  <div class="main-bonus-fl">
    <swiper
      :slides-per-view="4"
      :modules="modules"
      :navigation="{ nextEl: '.main-bonus-next', prevEl: '.main-bonus-prev' }"
      :pagination="{ clickable: true }"
      :breakpoints="{
        '320': {
          slidesPerView: 1,
        },
        '780': {
          slidesPerView: 2,
        },
        '1130': {
          slidesPerView: 3,
        },
        '1280': {
          slidesPerView: 4,
        },
        '1500': {
          slidesPerView: 4,
        },
      }"
    >
      <swiper-slide>
        <a href="#" class="main-bonus-item">
          <span class="main-bonus-item-image"
            ><object type="image/svg+xml" data="/images/banner-ani-1.svg"></object
            ><router-link :to="{ name: 'bonuses' }" class="banner-ani"></router-link
          ></span>
          <router-link :to="{ name: 'bonuses' }" class="main-bonus-item-more">{{ t('more', 'Подробнее') }}</router-link>
        </a>
      </swiper-slide>
      <swiper-slide>
        <a href="#" class="main-bonus-item">
          <span class="main-bonus-item-image"
            ><object type="image/svg+xml" data="/images/banner-ani-2.svg"></object
            ><router-link :to="{ name: 'bonuses' }" class="banner-ani"></router-link
          ></span>
          <router-link :to="{ name: 'bonuses' }" class="main-bonus-item-more">{{ t('more', 'Подробнее') }}</router-link>
        </a>
      </swiper-slide>
      <swiper-slide>
        <a href="#" class="main-bonus-item">
          <span class="main-bonus-item-image">
            <object type="image/svg+xml" data="/images/banner-ani-3.svg"></object>
            <router-link :to="{ name: 'register' }" class="banner-ani" v-if="!isAuthenticated"></router-link>
            <router-link :to="{ name: 'register' }" class="banner-ani a1"></router-link>
          </span>
          <router-link :to="{ name: 'register' }" class="main-bonus-item-more" v-if="!isAuthenticated">{{
            t('more', 'Подробнее')
          }}</router-link>
          <a href="#cashdesk" class="main-bonus-item-more a1">{{ t('more', 'Подробнее') }}</a>
        </a>
      </swiper-slide>
      <swiper-slide>
        <a href="#" class="main-bonus-item">
          <span class="main-bonus-item-image"
            ><object type="image/svg+xml" data="/images/banner-ani-4.svg"></object
            ><a href="https://t.me/millionb_club" class="banner-ani"></a
          ></span>
          <a
            data-v-f78bd9dc=""
            aria-current="page"
            href="https://t.me/millionb_club"
            class="router-link-active router-link-exact-active main-bonus-item-more"
            >Подробнее</a
          >
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useI18n } from 'vue-i18n'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Navigation } from 'swiper/modules'

export default {
  name: 'BonusesLine',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const { t } = useI18n()
    return {
      modules: [Pagination, Navigation],
      t,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
}
</script>

<style scoped>
.main-bonus-fl {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 -10px 0;
  position: relative;
}
.main-bonus-fl .swiper {
  width: calc(100% + 20px);
  padding: 10px 10px 25px 10px;
  margin: 0 -10px;
}

.main-bonus-fl .swiper-slide {
  justify-content: center;
  display: inline-flex;
}

.main-bonus-item {
  width: 342px;
  box-shadow: 2px 10px 18px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: block;
}

.main-bonus-item-image {
  display: block;
  height: 177px;
  overflow: hidden;
  background: rgba(192, 255, 0, 0.1);
  border-radius: 8px 8px 0 0;
  text-align: center;
  position: relative;
}

.main-bonus-item-more {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #81c105;
  display: block;
  position: relative;
}

.main-bonus-item-more:after {
  content: '';
  background: url("data:image/svg+xml,%3Csvg width='250' height='1' viewBox='0 0 250 1' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='1' width='1' height='250' transform='rotate(-90 0 1)' fill='url(%23paint0_linear)' fill-opacity='0.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='0.5' y1='1' x2='0.5' y2='251' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23A6D007' stop-opacity='0'/%3E%3Cstop offset='0.463542' stop-color='%23A6D007'/%3E%3Cstop offset='1' stop-color='%23A6D007' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")
    center top no-repeat;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.main-bonus-item:hover .main-bonus-item-more {
  background: linear-gradient(180deg, #a6d007 0%, #008d00 100%);
  box-shadow: 4px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #fff;
}
.banner-ani {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.banner-ani + .banner-ani.a1,
.main-bonus-item-more + .main-bonus-item-more.a1 {
  display: none;
}
</style>
