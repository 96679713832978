<template>
  <div class="index-tabs">
    <span
      @click="this.$router.push({ hash: '#topup' })"
      :class="this.isActiveNav(['#cashdesk', '#topup']) ? 'active-ttab' : ''"
      >Пополнить</span
    >
    <span @click="this.$router.push({ hash: '#getwin' })" :class="this.isActiveNav('#getwin') ? 'active-ttab' : ''"
      >Получить выигрыш</span
    >
    <span @click="this.$router.push({ hash: '#promocod' })" :class="this.isActiveNav('#promocod') ? 'active-ttab' : ''"
      >Промокод</span
    >
    <span @click="this.$router.push({ hash: '#bonus' })" :class="this.isActiveNav('#bonus') ? 'active-ttab' : ''"
      >Бонусы <i v-if="bonusesCount > 0">{{ bonusesCount }}</i></span
    >
  </div>

  <div class="index-panel">
    <div v-if="this.isActiveNav(['#cashdesk', '#topup'])" class="tt-panel in">
      <div class="index-panel-verification-phone" v-if="!depositsAllowed">
        <div class="index-panel-title">Требуется подтверждение телефона</div>
        <div class="index-panel-form"><verification-phone @confirmed="phoneConfirmed" /></div>
        <div class="index-panel-text">Для доступа к кассе подтвердите свой номер телефона с помощью смс.</div>
      </div>
      <template v-else>
        <PendingPayouts />
        <CashdeskTopUp />
      </template>
    </div>
    <div v-if="this.isActiveNav('#getwin')" class="tt-panel out">
      <PendingPayouts />
      <CashdeskGetWin />
    </div>
    <div v-if="this.isActiveNav('#promocod')" class="tt-panel">
      <CashdeskPromoCode />
    </div>
    <div v-if="this.isActiveNav('#bonus')" class="tt-panel">
      <CashdeskBonuses />
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import VueCountdown from '@chenfengyuan/vue-countdown'
import CashdeskTopUp from '@/components/cashdesk/TopUp'
import CashdeskGetWin from '@/components/cashdesk/GetWin'
import CashdeskBonuses from '@/components/cashdesk/Bonuses/index.vue'
import CashdeskPromoCode from '@/components/cashdesk/PromoCode/index.vue'
import PendingPayouts from '@/components/cashdesk/components/PendingPayouts.vue'
import VerificationPhone from '@/components/cashdesk/Verification/Phone.vue'

export default {
  name: 'CashdeskFinance',
  components: {
    VerificationPhone,
    PendingPayouts,
    CashdeskPromoCode,
    CashdeskBonuses,
    VueCountdown,
    CashdeskTopUp,
    CashdeskGetWin,
  },
  setup() {
    const { t, n } = useI18n()
    return { t, n }
  },
  data() {
    return {
      tab: 0,
      getWin: null,
      userConfirmed: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    bonusesCount() {
      const fs = this.currentUser?.status?.freespins ? this.currentUser?.status?.freespins.length : 0
      const bonuses = this.currentUser?.status?.bonuses ? this.currentUser?.status?.bonuses.length : 0
      return fs + bonuses
    },
    depositsAllowed() {
      if (this.currentUser?.trusted) {
        return true
      }
      return !!this.currentUser?.confirmed || this.userConfirmed
    },
  },
  methods: {
    isActiveNav(elem) {
      if (typeof elem === 'object') {
        return elem.includes(this.$route.hash)
      }
      return this.$route.hash === elem
    },
    phoneConfirmed() {
      this.userConfirmed = true
    },
  },
}
</script>

<style scoped></style>
